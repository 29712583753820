import { useState, useMemo } from 'react';

import { Box } from '@carbon/icons-react';
import { Tag, Popover, PopoverContent, Stack } from '@carbon/react';

import styles from './ContainerMapMarker.module.scss';
import { FullnessBar } from '../FullnessBar';

export type ContainerMapMarkerProps = {
  containerSiteName: string;
  serviceLocationName: string;
  maxWeight?: number;
  weight?: number;
  weightLabel: string;
  weightDisplay: string;
  onClick: () => void;
};

export const ContainerMapMarker: React.FunctionComponent<
  ContainerMapMarkerProps
> = ({
  containerSiteName,
  serviceLocationName,
  maxWeight,
  weight,
  weightLabel,
  weightDisplay,
  onClick,
}) => {
  const [open, setOpen] = useState(false);

  const type = useMemo(() => {
    if (!maxWeight || !weight) {
      return 'outline';
    }

    const percentFull = weight / maxWeight;

    if (percentFull >= 1) {
      return 'red';
    } else if (percentFull >= 0.8) {
      return 'magenta';
    } else if (percentFull) {
      return 'green';
    }
  }, [maxWeight, weight]);

  return (
    <Popover open={open}>
      <Tag
        as="div"
        className={styles.tag}
        renderIcon={Box}
        type={type}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={onClick}
      >
        {serviceLocationName} {containerSiteName}
      </Tag>
      <PopoverContent className={styles.popoverContent}>
        <Stack>
          <FullnessBar
            maxWeight={maxWeight}
            weight={weight}
            weightLabel={weightLabel}
            weightDisplay={weightDisplay}
            showHelper
          />
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
