export enum Permission {
  CAN_READ_ACCOUNTS = 'CAN_READ_ACCOUNTS',

  CAN_READ_USERS = 'CAN_READ_USERS',

  CAN_READ_BIN_BARS = 'CAN_READ_BIN_BARS',

  CAN_CREATE_SERVICE_LOCATIONS = 'CAN_CREATE_SERVICE_LOCATIONS',
  CAN_READ_SERVICE_LOCATIONS = 'CAN_READ_SERVICE_LOCATIONS',
  CAN_UPDATE_SERVICE_LOCATIONS = 'CAN_UPDATE_SERVICE_LOCATIONS',
  CAN_DELETE_SERVICE_LOCATIONS = 'CAN_DELETE_SERVICE_LOCATIONS',

  CAN_CREATE_CONTAINER_SITES = 'CAN_CREATE_CONTAINER_SITES',
  CAN_READ_CONTAINER_SITES = 'CAN_READ_CONTAINER_SITES',
  CAN_READ_CONTAINER_SITE_DETAILS = 'CAN_READ_CONTAINER_SITE_DETAILS',
  CAN_UPDATE_CONTAINER_SITES = 'CAN_UPDATE_CONTAINER_SITES',
  CAN_DELETE_CONTAINER_SITES = 'CAN_DELETE_CONTAINER_SITES',
  CAN_UPDATE_CONTAINER_SITE_INTERNAL_NOTES = 'CAN_UPDATE_CONTAINER_SITE_INTERNAL_NOTES',

  CAN_UPDATE_CONTAINER_CYCLES = 'CAN_UPDATE_CONTAINER_CYCLES',

  CAN_CREATE_SERVICE_REQUEST = 'CAN_CREATE_SERVICE_REQUEST',
  CAN_READ_SERVICE_REQUEST = 'CAN_READ_SERVICE_REQUEST',
  CAN_UPDATE_SERVICE_REQUEST = 'CAN_UPDATE_SERVICE_REQUEST',

  CAN_VIEW_WEIGHT_TICKETS = 'CAN_VIEW_WEIGHT_TICKETS',
  CAN_UPLOAD_WEIGHT_TICKETS = 'CAN_UPLOAD_WEIGHT_TICKETS',
  CAN_UPDATE_WEIGHT_TICKETS = 'CAN_UPDATE_WEIGHT_TICKETS',
  CAN_DELETE_WEIGHT_TICKETS = 'CAN_DELETE_WEIGHT_TICKETS',

  CAN_VIEW_INSTALL_PHOTOS = 'CAN_VIEW_INSTALL_PHOTOS',
  CAN_UPLOAD_INSTALL_PHOTOS = 'CAN_UPLOAD_INSTALL_PHOTOS',

  CAN_VIEW_REMOVAL_PHOTOS = 'CAN_VIEW_REMOVAL_PHOTOS',
  CAN_UPLOAD_REMOVAL_PHOTOS = 'CAN_UPLOAD_REMOVAL_PHOTOS',

  CAN_VIEW_ADVANCED_METRICS = 'CAN_VIEW_ADVANCED_METRICS',
  CAN_CREATE_REPORTS = 'CAN_CREATE_REPORTS',
}
