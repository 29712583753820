import { Permission } from './permissions';
import { AccountRole } from './roles';

type PolicyValue = `extend:${string}` | Permission;

const target: { [key in AccountRole]: PolicyValue[] } = {
  [AccountRole.VIEWER]: [
    Permission.CAN_READ_ACCOUNTS,
    Permission.CAN_READ_USERS,
    Permission.CAN_READ_SERVICE_LOCATIONS,
    Permission.CAN_READ_CONTAINER_SITES,
    Permission.CAN_READ_BIN_BARS,
  ],
  [AccountRole.DRIVER]: [
    'extend:VIEWER',
    Permission.CAN_UPLOAD_INSTALL_PHOTOS,
    Permission.CAN_UPLOAD_REMOVAL_PHOTOS,
  ],
  [AccountRole.USER]: [
    'extend:VIEWER',
    Permission.CAN_CREATE_SERVICE_LOCATIONS,
    Permission.CAN_UPDATE_SERVICE_LOCATIONS,
    Permission.CAN_DELETE_SERVICE_LOCATIONS,
    Permission.CAN_READ_CONTAINER_SITE_DETAILS,
    Permission.CAN_CREATE_CONTAINER_SITES,
    Permission.CAN_UPDATE_CONTAINER_SITES,
    Permission.CAN_DELETE_CONTAINER_SITES,
    Permission.CAN_VIEW_WEIGHT_TICKETS,
    Permission.CAN_UPLOAD_WEIGHT_TICKETS,
    Permission.CAN_VIEW_INSTALL_PHOTOS,
    Permission.CAN_VIEW_REMOVAL_PHOTOS,
    Permission.CAN_UPLOAD_INSTALL_PHOTOS,
    Permission.CAN_UPLOAD_REMOVAL_PHOTOS,
  ],
  [AccountRole.ADMINISTRATOR]: [
    'extend:USER',
    Permission.CAN_DELETE_WEIGHT_TICKETS,
  ],
  [AccountRole.ACCOUNT_MANAGER]: [
    'extend:ADMINISTRATOR',
    Permission.CAN_VIEW_ADVANCED_METRICS,
    Permission.CAN_CREATE_REPORTS,
    Permission.CAN_UPDATE_CONTAINER_CYCLES,
    Permission.CAN_UPDATE_WEIGHT_TICKETS,
    Permission.CAN_UPDATE_CONTAINER_SITE_INTERNAL_NOTES,
  ],
};

const isPolicyExtension = (
  policyValue: PolicyValue,
): policyValue is `extend:${string}` =>
  policyValue.toString().startsWith('extend');

const getPermissionsForExtension = (
  target: { [key in AccountRole]: PolicyValue[] },
  policyValue: `extend:${string}`,
): Permission[] => {
  const permissions: Permission[] = [];
  const policyExtensionKey = policyValue.replace('extend:', '').trim();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  target[AccountRole[policyExtensionKey]].forEach((pv: PolicyValue) => {
    if (isPolicyExtension(pv)) {
      permissions.push(...getPermissionsForExtension(target, pv));
    } else {
      permissions.push(pv);
    }
  });

  return permissions;
};

const handler = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(target: any, propertyKey: string | symbol, receiver: unknown) {
    const policyValues: PolicyValue[] = Reflect.get(
      target,
      propertyKey,
      receiver,
    );

    return policyValues.reduce<Permission[]>((acc, policyValue) => {
      if (isPolicyExtension(policyValue)) {
        acc.push(...getPermissionsForExtension(target, policyValue));
      } else {
        acc.push(policyValue);
      }
      return acc;
    }, []);
  },
};

export const policy: { [key in AccountRole]: Permission[] } = new Proxy(
  target,
  handler,
);
