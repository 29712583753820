import type { JSX } from 'react';

import {
  Tag,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react';

export type StatusTagsProps = {
  isTraining: boolean;
  isOffline: boolean;
  batteryLevel?: number;
  currentWeight?: number;
  maxWeight?: number;
};

export const StatusTags: React.FunctionComponent<StatusTagsProps> = ({
  isTraining,
  isOffline,
  batteryLevel,
  currentWeight,
  maxWeight,
}) => {
  const tags: JSX.Element[] = [];

  if (isTraining) {
    tags.push(
      <Toggletip align="bottom" key="training">
        <ToggletipButton label="Show information">
          <Tag type="red" data-testid="status-tag">
            Training
          </Tag>
        </ToggletipButton>
        <ToggletipContent>
          <p>
            This BinBar is learning from previous container cycles. To
            facilitate this training, please provide all of your weight tickets
            to your account manager
          </p>
        </ToggletipContent>
      </Toggletip>,
    );
  }

  if (isOffline) {
    tags.push(
      <Tag type="outline" data-testid="status-tag" key="offline">
        Offline
      </Tag>,
    );
  } else {
    tags.push(
      <Tag type="green" data-testid="status-tag" key="online">
        Online
      </Tag>,
    );
  }

  if (batteryLevel !== undefined && batteryLevel < 0.1) {
    tags.push(
      <Tag type="blue" data-testid="status-tag" key="lowBattery">
        Low battery
      </Tag>,
    );
  }

  if (currentWeight !== undefined && maxWeight !== undefined) {
    const fullness = currentWeight / maxWeight;

    if (fullness >= 1) {
      tags.push(
        <Tag type="red" data-testid="status-tag" key="overweight">
          Overweight
        </Tag>,
      );
    }
  }

  return tags;
};
